export const download = async (url, name) => {
    const fileUrl = url;

    try {
        const response = await fetch(fileUrl,{ mode: 'no-cors'});
        await response.blob();

        // const downloadUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = name;
        document.body.appendChild(link);
        link.click();

        // window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading the file:', error.message);
    }
};